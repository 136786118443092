<template>
  <div>
    <Header></Header>
    <div class="conter">
      <!-- 左侧选项 -->
      <div class="qiehuai">
        <img src="@/img/buymem.png" class="user_img" />
        <router-link to="/index/zixun" class="qiehuai_item">
          <i class="iconfont icon-xiaoxi"></i>
          <p>网站咨询</p>
          <span v-if="znum > 0"></span>
        </router-link>
        <router-link to="/index/order" class="qiehuai_item">
          <i class="iconfont icon-maijiaoliu"></i>
          <p>订单咨询</p>
          <span v-if="onum > 0"></span>
        </router-link>
        <a href="javascript:;" class="qiehuai_item tishi">
          <i class="iconfont icon-yinliangxuanzhong"></i>
          <p>提示(开)</p>
        </a>
      </div>
      <keep-alive>
        <router-view @oedershu="getonum" @zixunshu="getznum"></router-view>
      </keep-alive>
    </div>
  </div>
</template>

<script>
import Header from '@/components/header/header'
export default {
  name: 'Index',
  components: { Header },
  props: [],
  data() {
    return {
      znum: 0,
      onum: 0
    }
  },
  watch: {},
  computed: {},
  methods: {
    getonum(e) {
      console.log(e)
      this.onum = e
    },
    getznum(e) {
      this.znum = e
    }
  },
  created() {},
  mounted() {},
  filters: {}
}
</script>
<style lang="less" scoped>
// 左侧选项
.qiehuai {
  width: 64px;
  height: calc(100vh - 50px);
  background-color: #0081fb;
  display: inline-block;
  vertical-align: top;
  .user_img {
    width: 32px;
    height: 32px;
    border-radius: 3px;
    margin-top: 16px;
    margin-bottom: 16px;
  }
  .qiehuai_item {
    width: 100%;
    text-align: center;
    padding: 5px 0;
    display: inline-block;
    text-decoration: none;
    position: relative;
    .iconfont {
      font-size: 26px;
      color: #7ac1ff;
    }
    p {
      font-size: 12px;
      color: #7ac1ff;
      margin-top: 5px;
    }
    span {
      border-radius: 50%;
      background-color: #ff0000;
      position: absolute;
      width: 10px;
      height: 10px;
      top: 5px;
      right: 5px;
    }
  }
  .qiehuai_item.router-link-active {
    background-color: #ff9f00;
    .iconfont {
      color: #fff;
    }
    p {
      color: #fff;
    }
  }
  .qiehuai_item.tishi {
    .iconfont {
      color: #fff;
    }
    p {
      color: #fff;
    }
  }
}
</style>
