<template>
  <div class="cont">
    <!-- 聊天框 -->
    <div class="liaotian_cont" id="data-list-content" ref="xinxikuang">
      <template v-for="(item, inde) in xinxishuju">
        <template v-if="item.mem == '用户' || item.mem.indexOf('客服') < 0">
          <div class="left_cont" :key="inde">
            <p class="xitong_tit">
              <span>{{ item.mem }}</span>
              <span>{{ item.data }}</span>
            </p>
            <div class="kanzhongacc" v-if="item.txt.includes('chatfasongshop')">
              <div class="chatfasongshop" v-html="tihuan(item.txt)" ref="chatfasongshop" @click="chankanxiangqing(item.txt)"></div>
            </div>
            <template v-else-if="item.txt.includes('chatqiugoushop')">
              <div class="kanzhongacc" v-html="item.txt"></div>
            </template>
            <template v-else>
              <img :src="getImgUrl(item.txt)" v-if="item.txt.includes('img.yx915.com') || item.txt.includes('longxiaimg.oss')" @click="showImg($event)" />
              <p class="xitong_fayan" v-else>{{ item.txt }}</p>
            </template>
          </div>
        </template>
        <template v-else>
          <div class="right_cont" :key="inde">
            <div class="my_tit">
              <p>接手客服：{{ item.mem }} {{ item.data }}</p>
            </div>
            <img :src="item.txt" v-if="item.txt.includes('img.yx915.com') || item.txt.includes('longxiaimg.oss')" @click="showImg($event)" />
            <div class="my_fayan" v-else>
              <p>{{ item.txt }}</p>
            </div>
          </div>
        </template>
      </template>
    </div>
    <!-- 功能按钮 -->
    <div class="gongnenganniu">
      <i class="iconfont icon-a-tupian" @click="chooseImage"></i>
      <input @change="photoChange($event)" type="file" id="upload_file" multiple style="display: none" />
    </div>
    <!-- 输入栏 -->
    <div class="shurulan">
      <textarea class="maijiafasong" v-model="fasongxinxi" @keyup.enter.exact="zx_Write('txt')" @keyup.ctrl.enter="linefeed"></textarea>
      <div>
        <p>Enter发送</p>
        <a href="javascript:;" @click="zx_Write('txt')">发送</a>
      </div>
    </div>
    <!-- 图片放大 -->
    <div v-if="showimg" class="fangda_cont" @click="closeimg">
      <div class="mengc"></div>
      <img :src="fangdaimg" class="fangdatu" v-if="showimg" ref="imgchicun" />
    </div>
  </div>
</template>

<script>
// import MyWorker from '@/a.worker.js'
export default {
  name: 'XinXi',
  components: {},
  props: ['code'],
  data() {
    return {
      xinxishuju: [],
      fasongxinxi: '',
      fasongimng: '',
      fangdaimg: '',
      showimg: false,
      ch: 0,
      badwordsList: [],
      workerData: false,
      // worker: null
      timer1: null
    }
  },
  created() {
    this.getjson()
  },
  mounted() {
    this.ch = this.$refs.xinxikuang.scrollHeight
  },
  watch: {
    code() {
      this.xinxishuju = []

      // if (!this.workerData) {
      //   this.workerData = true
      //   this.zixunxinxi()
      // } else {
      //   const url = `https://img.yx915.com/json/livechat/${this.code}/${this.code}.json?v=${Math.random()}`
      //   this.worker.postMessage({
      //     action: 'updateApi',
      //     apiUrl: url
      //   })
      // }
      if (this.timer1 == null) {
        this.timer1 = setInterval(() => {
          // 某些操作
          this.zixunxinxi()
        }, 1000)
      } else {
        clearInterval(this.timer1)
        this.timer1 = setInterval(() => {
          // 某些操作
          this.zixunxinxi()
        }, 1000)
      }
    },
    xinxishuju(a, b) {
      // console.log(
      //   this.$refs.xinxikuang.scrollHeight - this.$refs.xinxikuang.scrollTop
      // )
      // console.log(this.$refs.xinxikuang.scrollHeight)
      const len1 = a.length // 新数据
      const len2 = b.length // 旧数据
      if (len2 === 0) {
        this.$nextTick(() => {
          this.$refs.xinxikuang.scrollTop = this.$refs.xinxikuang.scrollHeight
        })
        return
      }
      if (this.$refs.xinxikuang.scrollHeight - this.$refs.xinxikuang.scrollTop === this.ch) {
        this.$nextTick(() => {
          this.$refs.xinxikuang.scrollTop = this.$refs.xinxikuang.scrollHeight
        })
        return
      }
      if (len1 === len2) return
      // if (a[len1 - 1].mem === '用户') return
      this.$nextTick(() => {
        this.$refs.xinxikuang.scrollTop = this.$refs.xinxikuang.scrollHeight
      })
    },
    fangdaimg() {
      this.$nextTick(() => {
        if (this.fangdaimg === '') {
          this.showimg = false
        } else {
          this.showimg = true
        }

        // this.$refs.imgchicun
      })
    }
  },
  computed: {},
  methods: {
    async getjson() {
      const { data: res } = await this.$axios.get('https://img.yx915.com/TxtDate/keyss.txt')
      this.badwordsList = res.replaceAll('\n', '').replaceAll('\r', '').split(',')
    },
    handleInput(zhi) {
      let filtered = zhi
      if (this.badwordsList) {
        for (const word of this.badwordsList) {
          const escapedWord = word.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')
          const reg = new RegExp(escapedWord, 'gi')
          filtered = filtered.replace(reg, '*'.repeat(word.length))
        }
      }
      return filtered
    },
    // 获取临时咨询信息
    async zixunxinxi() {
      try {
        const url = `https://img.yx915.com/json/livechat/${this.code}/${this.code}.json?v=${Math.random()}`

        // 创建 Web Worker 实例
        // this.worker = new MyWorker()

        // 监听 Web Worker 的消息
        // this.worker.addEventListener('message', (event) => {
        //   this.xinxishuju = event.data
        // })

        // 启动 Web Worker 定时器
        // this.worker.postMessage({
        //   action: 'start',
        //   apiUrl: url
        // })

        const { data: res } = await this.$axios.get(url, {
          dataType: 'text'
        })
        this.xinxishuju = res
      } catch (error) {
        console.error(error)
      }
    },
    // 信息换行
    linefeed() {
      this.fasongxinxi += '\n'
    },
    // 客服发送信息给用户
    async zx_Write(sta) {
      if (sta === 'txt') {
        if (this.fasongxinxi === '') {
          alert('请输入信息')
          this.fasongxinxi = ''
          return
        }
      }

      const shijianc = new Date().getTime()
      const obj = {
        dd: this.code,
        txt: this.fasongxinxi,
        mem: localStorage.name
      }
      if (sta === 'img') {
        obj.txt = this.fasongimng
      }
      try {
        const { data: res } = await this.$axios.post('https://web.api.yx915.com/Chatsystem/zx_Write.aspx', JSON.stringify(obj), {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'x-service-app': 'chenshi001',
            'x-service-token': this.$md5('chenshi001' + 'd832912d76d935cab49c76462312c848' + shijianc).toUpperCase(),
            'x-service-timestamp': shijianc
          }
        })
        if (res[0].msg === '成功') {
          this.fasongxinxi = ''
          this.zx_Write8()
        }
      } catch (error) {
        console.log(error)
      }
    },
    // 清除未读数
    async zx_Write8() {
      try {
        const shijianc = new Date().getTime()
        const obj = {
          dd: this.code,
          txt: 0
        }
        const response = await this.$axios.post('https://Web.api.yx915.com/Chatsystem/zx_Write8.aspx', JSON.stringify(obj), {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'x-service-app': 'chenshi001',
            'x-service-token': this.$md5('chenshi001' + 'd832912d76d935cab49c76462312c848' + shijianc),
            'x-service-timestamp': shijianc
          }
        })
        console.log(response.data)
      } catch (error) {
        console.log(error)
      }
    },
    // 上传图片 start
    chooseImage() {
      document.getElementById('upload_file').click() // 触发input-file文件上传控件 事件photoChange
    },
    photoChange(el) {
      const file = el.target.files // name: "dangqi1.png" || type: "image/png"
      for (let i = 0; i < file.length; i++) {
        const type = file[i].type.split('/')[0]
        if (type === 'image') {
          // 将图片img转化为base64
          const reader = new FileReader()
          reader.readAsDataURL(file[i])
          const that = this
          reader.onloadend = function () {
            const dataURL = reader.result
            const blob = that.dataURItoBlob(dataURL)
            that.upload(blob) // 执行上传接口
          }
        } else {
          alert('上传了非图片')
        }
      }
    },
    dataURItoBlob(dataURI) {
      // base64 解码
      const byteString = window.atob(dataURI.split(',')[1])
      const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]
      // const T = mimeString.split('/')[1]
      const ab = new ArrayBuffer(byteString.length)
      const ia = new Uint8Array(ab)
      for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i)
      }
      return new Blob([ab], { type: mimeString })
    },
    async upload(imgUrl) {
      const shijianc = new Date().getTime()
      const formdata = new FormData()
      formdata.append('file', imgUrl) // 下面是要传递的参数

      const { data: res } = await this.$axios.post('https://Web.api.yx915.com/Imgupdate/Url_Img_Zx.aspx', formdata, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'x-service-app': 'chenshi001',
          'x-service-token': this.$md5('chenshi001' + 'd832912d76d935cab49c76462312c848' + shijianc).toUpperCase(),
          'x-service-timestamp': shijianc
        }
      })
      // console.log(res)
      this.fasongimng = res.data.src
      this.zx_Write('img')
    }, // 上传图片 end
    // 放大图片
    showImg(e) {
      if (e.target.src) {
        this.fangdaimg = e.target.src
      }
    },
    closeimg() {
      this.showimg = false
      this.fangdaimg = ''
    },
    tihuan(item) {
      // 处理点击事件的逻辑
      console.log(item)
      let str = item.replace('div', 'a target="_blank"')
      const lastIndex = str.lastIndexOf('div')

      // 判断是否找到了 9
      if (lastIndex !== -1) {
        // 将字符串分成两部分，前一部分和后一部分
        const before = str.slice(0, lastIndex)
        const after = str.slice(lastIndex + 1)

        // 将最后一个 9 替换成 1，并拼接前一部分和后一部分
        str = before + 'a' + after
      }
      return str
    },
    getImgUrl(html) {
      const RegEx = /(?<=(img src="))[^"]*?(?=")/gims
      if (html && html.match(RegEx) && html.match(RegEx).length > 0) {
        return html.match(RegEx)[0]
      }
      return html
    },
    chankanxiangqing(str) {
      // console.log(this.$refs.chatfasongshop[inde])
      const reg = /(https?|http|ftp|file):\/\/[-A-Za-z0-9+&@#/%?=~_|!:,.;]+[-A-Za-z0-9+&@#/%=~_|]/g
      const strValue = str.match(reg)
      if (strValue && strValue.length > 0) {
        window.open(strValue[0])
      }
    }
  },
  filters: {},
  beforeDestroy() {
    // this.worker.postMessage({ action: 'stop' })
    // this.worker.terminate()

    clearInterval(this.timer1)
    this.timer1 = null
  },
  destroyed() {
    // this.worker.postMessage({ action: 'stop' })
    // this.worker.terminate()

    clearInterval(this.timer1)
    this.timer1 = null
  }
}
</script>
<style lang="less" scoped>
.cont {
  width: 1050px;
  text-align: left;
  background-color: #f5f5f5;
  display: inline-block;
  vertical-align: top;
  .liaotian_cont {
    width: 1050px;
    height: calc(100vh - 284px);
    border: 1px solid #e5e5e5;
    padding: 0 20px;
    box-sizing: border-box;
    overflow: auto;
    padding-bottom: 10px;
    .xitong_tit {
      margin-top: 15px;
      span {
        display: inline-block;
        vertical-align: middle;
        font-size: 12px;
      }
      span:nth-child(1) {
        color: rgb(255, 91, 1);
      }
      span:nth-child(2) {
        margin-left: 8px;
        color: rgb(153, 153, 153);
      }
    }
    .xitong_fayan {
      margin-top: 10px;
      text-align: left;
      line-height: 1.6;
      padding: 6px;
      box-sizing: border-box;
      color: rgb(77, 77, 77);
      font-size: 12px;
      display: inline-block;
      background-color: #9eea65;
      border-radius: 3px;
      text-align: justify;
      max-width: 70%;
      ::v-deep .getRecord {
        img {
          width: 166px !important;
        }
      }
    }
    .kefu_tit {
      margin-top: 15px;
      span {
        display: inline-block;
        vertical-align: middle;
        font-size: 12px;
      }
      span:nth-child(1) {
        color: rgb(0, 0, 0);
      }
      span:nth-child(2) {
        margin-left: 8px;
        color: rgb(153, 153, 153);
      }
    }
    .kefu_fayan {
      margin-top: 10px;
      text-align: left;
      border-radius: 3px;
      background-color: #e6e6e6;
      color: rgb(77, 77, 77);
      font-size: 12px;
      line-height: 1.6;
      padding: 6px;
      box-sizing: border-box;
      display: inline-block;
      text-align: justify;
      max-width: 70%;
      white-space: pre-wrap;
      ::v-deep .getRecord {
        img {
          width: 166px !important;
        }
      }
    }
    .my_tit {
      margin-top: 15px;
      text-align: right;
      p {
        font-size: 12px;
        color: rgb(153, 153, 153);
      }
    }
    .my_fayan {
      text-align: right;
      p {
        margin-top: 10px;
        line-height: 1.6;
        padding: 6px;
        box-sizing: border-box;
        color: rgb(77, 77, 77);
        font-size: 12px;
        display: inline-block;
        background-color: #fff;
        border-radius: 3px;
        text-align: justify;
        max-width: 70%;
        word-wrap: break-word;
        white-space: pre-wrap;
        ::v-deep .getRecord {
          img {
            width: 166px !important;
          }
        }
        ::v-deep .pingjia {
          display: none;
        }
        ::v-deep .kefupingjia {
          font-size: 12px;
          color: #f00;
        }
      }
    }
    ::v-deep .getRecord {
      margin-top: 5px;
      img {
        max-width: 166px;
      }
    }
    .left_cont {
      text-align: left;
      img {
        border-radius: 6px;
        margin-top: 5px;
        max-width: 230px;
      }
    }
    .right_cont {
      text-align: right;
      img {
        border-radius: 6px;
        margin-top: 5px;
        max-width: 230px;
      }
    }
  }
  .gongnenganniu {
    height: 44px;
    line-height: 44px;
    padding: 0 20px;
    border-left: 1px solid #e5e5e5;
    border-right: 1px solid #e5e5e5;
    width: 100%;
    box-sizing: border-box;
    .lianxi_btn {
      float: right;
      position: relative;
      height: 44px;
      line-height: 44px;
      text-align: center;
      width: 110px;
      cursor: pointer;
      display: inline-block;
      vertical-align: middle;
      .iconfont {
        color: #ff9f00;
        display: inline-block;
        vertical-align: middle;
      }
      p {
        font-size: 14px;
        color: #000000;
        margin-left: 5px;
        display: inline-block;
        vertical-align: middle;
      }
      span {
        display: block;
        border-radius: 50%;
        background-color: #ff0000;
        font-size: 12px;
        position: absolute;
        top: 2px;
        right: 2px;
        color: #fff;
        width: 20px;
        height: 20px;
        line-height: 20px;
        text-align: center;
        display: none;
      }
    }
    .lianxi_btn.act {
      background-color: #fff;
    }
  }
  .shurulan {
    width: 100%;
    height: 135px;
    background-color: #fff;
    border-left: 1px solid #e5e5e5;
    border-right: 1px solid #e5e5e5;
    padding: 20px;
    box-sizing: border-box;
    textarea {
      width: 100%;
      border: 0;
      font-size: 14px;
      color: #000000;
      height: 60px;
      resize: none;
      outline: none;
    }
    div {
      text-align: right;
      margin-top: 5px;
      p {
        font-size: 12px;
        color: #999999;
        height: 30px;
        line-height: 30px;
        display: inline-block;
        vertical-align: middle;
      }
      a {
        display: inline-block;
        margin-left: 8px;
        height: 30px;
        line-height: 30px;
        width: 64px;
        color: #fff;
        font-size: 14px;
        background-color: #0081fb;
        text-align: center;
        border-radius: 5px;
        text-decoration: none;
      }
    }
  }
}
.fangda_cont {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: space-around;
  align-items: center;
  .mengc {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
  }
  img {
    position: absolute;
    max-height: 80%;
  }
}

.kanzhongacc {
  .chatfasongshop {
    z-index: 999;
    margin-top: 10px;
    background: #fff;
    border-radius: 4px;
    display: flex;
    align-items: center;
    padding: 10px 26px 10px 10px;
    box-sizing: border-box;
    text-align: justify;
    width: 600px;
    text-decoration: none;
    color: #000000;
    cursor: pointer;
  }
  /deep/ .chatfasongshop {
    img {
      width: 100px;
      height: 66px;
    }
    .fasong_body {
      margin-left: 10px;
      p:nth-child(1) {
        display: -webkit-box;
        overflow: hidden;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        font-size: 14px;
        span:nth-child(2) {
          color: #f00;
        }
      }
      div {
        display: flex;
        justify-content: space-between;
        margin-top: 10px;
        p:nth-child(2) {
          width: 69px;
          height: 20px;
          line-height: 20px;
          border-radius: 4px;
          border: 1px solid #4594e8;
          text-align: center;
          font-size: 12px;
          font-family: PingFang SC-Medium, PingFang SC;
          font-weight: 500;
          color: #4594e8;
        }
      }
    }
    .van-icon {
      position: absolute;
      right: 5px;
      top: 5px;
      color: #999;
      font-size: 14px;
    }
  }

  /deep/ .chatqiugoushop {
    z-index: 999;
    // margin-left: 10px;
    background: #fff;
    border-radius: 4px;
    display: flex;
    align-items: center;
    padding: 10px 26px 10px 10px;
    box-sizing: border-box;
    text-align: justify;
    margin-top: 10px;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    max-width: 60%;
    .accxinxi {
      width: 100%;
    }
    .accxinxi_one {
      // min-height: 71px;
      .tab_tit {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        text-decoration: none;
        cursor: pointer;
        span.tubiao {
          display: inline-block;
          vertical-align: middle;
          img {
            display: inline-block;
            vertical-align: middle;
            margin-right: 3px;
          }
        }
        span.textnr {
          vertical-align: middle;
          font-size: 14px;
          color: #1a1a1a;
          font-weight: bold;
        }
      }
      .tab_qufu {
        font-size: 14px;
        color: #808080;
        margin-top: 10px;
      }
      .shenglv {
        display: inline-block !important;
        cursor: pointer;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
    }
    .accxinxi_two {
      display: flex;
      justify-content: space-between;
      .tab_price {
        font-size: 14px;
        color: #f00;
      }
      a {
        width: 69px;
        height: 20px;
        line-height: 20px;
        border-radius: 4px 4px 4px 4px;
        border: 1px solid #4594e8;
        font-size: 12px;
        font-family: PingFang SC-Medium, PingFang SC;
        font-weight: 500;
        color: #4594e8;
        text-align: center;
        cursor: pointer;
      }
    }
  }
}
</style>
