<template>
  <div class="cont">
    <img src="@/img/loginbj.jpg" class="bjimg" />
    <div class="form">
      <div class="img_nr">
        <img src="@/img/logo.png" />
      </div>
      <input
        type="text"
        placeholder="手机号"
        v-model.trim="dianhua"
        @keyup.enter="denglu"
      />
      <input
        type="password"
        placeholder="请输入密码"
        v-model.trim="mima"
        @keyup.enter="denglu"
      />
      <button @click="denglu">登录</button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Login',
  components: {},
  props: [],
  data() {
    return {
      dianhua: '',
      mima: ''
    }
  },
  watch: {},
  computed: {},
  methods: {
    async denglu() {
      if (this.dianhua === '') return alert('请填写账号')
      if (this.mima === '') return alert('请填写密码')
      const obj = {
        number: this.dianhua,
        pass: this.mima
      }
      const { data: res } = await this.$axios({
        method: 'get',
        url: 'https://web.api.yx915.com/Chatsystem/N_LOGIN.aspx',
        params: obj // get请求参数
      })
      // console.log(res.MODE)
      if (res.MODE) {
        localStorage.token = res.MODE
        localStorage.leixing = res.LEIXING
        localStorage.name = res.NAME
        this.$router.replace('/index')
      } else {
        if (res[0].msg === '失败') {
          alert(res[0].number)
        }
      }
    }
  },
  created() {
    if (window.Notification) {
      // 支持
      console.log('支持' + 'Web Notifications API')
      // 如果支持Web Notifications API，再判断浏览器是否支持弹出实例
    } else {
      // 不支持
      alert('您的浏览器不支持信息通知！！！请更换！！！')
    }
  },
  mounted() {},
  filters: {}
}
</script>
<style lang="less" scoped>
.cont {
  width: 100%;
  height: 100vh;
  overflow: hidden;
}
.bjimg {
  width: 100%;
}
.form {
  width: 460px;
  height: 400px;
  padding: 30px;
  box-sizing: border-box;
  background-color: #fff;
  border-radius: 10px;
  position: fixed;
  left: 730px;
  top: 200px;
  .img_nr {
    text-align: center;
    padding-bottom: 70px;
    img {
      width: 186px;
    }
  }
  input {
    display: block;
    width: 100%;
    height: 30px;
    line-height: 30px;
    font-size: 14px;
    border: 0;
    border-bottom: 1px solid #e5e5e5;
    margin-top: 20px;
  }
  input:focus {
    outline: none;
  }
  button {
    width: 100%;
    height: 50px;
    line-height: 50px;
    border: 1px solid #e5e5e5;
    background-color: #1989fa;
    color: #fff;
    font-size: 14px;
    font-weight: bold;
    border-radius: 5px;
    margin-top: 50px;
    cursor: pointer;
  }
}
</style>
