<template>
  <div class="header">
    <div>
      <img src="@/img/logo.png" />
      <p>客服后台系统</p>
    </div>
    <div>
      <img src="@/img/buymem.png" />
      <p>{{ name }}</p>
      <a href="#" @click.stop="xiaban">下班</a>
      <!-- <img src="@/img/guanji.png" @click="logout" /> -->
    </div>
  </div>
</template>

<script>
export default {
  name: 'Header',
  components: {},
  props: [],
  data() {
    return {
      name: localStorage.name
    }
  },
  watch: {},
  computed: {},
  methods: {
    // 退出登录
    logout() {
      localStorage.removeItem('token')
      localStorage.removeItem('leixing')
      localStorage.removeItem('name')
      this.$router.push('/login')
    },
    async xiaban() {
      const obj = {
        Mem: localStorage.token
      }
      await this.$axios({
        method: 'get',
        url: 'https://web.api.yx915.com/Chatsystem/N_DOWN.aspx',
        params: obj // get请求参数
      })
      localStorage.removeItem('token')
      localStorage.removeItem('leixing')
      localStorage.removeItem('name')
      this.$router.push('/login')
    }
  },
  created() {},
  mounted() {},
  filters: {}
}
</script>
<style lang="less" scoped>
.header {
  padding: 0 15px;
  background-color: #fff;
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  div:nth-child(1) {
    img {
      height: 30px;
      display: inline-block;
      vertical-align: middle;
    }
    p {
      font-size: 15px;
      font-weight: bold;
      color: #f00;
      margin-left: 15px;
      height: 50px;
      line-height: 50px;
      display: inline-block;
      vertical-align: middle;
    }
  }
  div:nth-child(2) {
    img:nth-child(1) {
      width: 32px;
      height: 32px;
      display: inline-block;
      vertical-align: middle;
    }
    p {
      font-size: 16px;
      color: #000;
      margin-left: 10px;
      display: inline-block;
      vertical-align: middle;
      height: 50px;
      line-height: 50px;
    }
    a:nth-child(3) {
      display: inline-block;
      vertical-align: middle;
      font-size: 14px;
      height: 30px;
      line-height: 30px;
      padding: 0 8px;
      border-radius: 5px;
      margin-left: 5px;
      color: #fff;
      background-color: #1989fa;
      text-decoration: none;
    }
    img:nth-child(4) {
      width: 20px;
      display: inline-block;
      vertical-align: middle;
      margin-left: 20px;
      cursor: pointer;
    }
  }
}
</style>
