<template>
  <div class="cont">
    <!-- 聊天框 -->
    <div class="liaotian_cont" ref="xinxikuang">
      <template v-for="(item, inde) in xinxishuju">
        <template v-if="item.mem == '用户'">
          <div :key="inde">
            <p class="xitong_tit">
              <span>YX915用户</span><span>{{ item.data }}</span>
            </p>
            <p class="xitong_fayan" v-html="item.txt" @click="showImg($event)"></p>
          </div>
        </template>
        <template v-else>
          <div :key="inde">
            <div class="my_tit">
              <p>接手客服：{{ item.mem }} {{ item.data }}</p>
            </div>
            <div class="my_fayan">
              <p v-html="item.txt" @click="showImg($event)"></p>
            </div>
          </div>
        </template>
      </template>
    </div>
    <!-- 功能按钮 -->
    <div class="gongnenganniu">
      <i class="iconfont icon-a-tupian" @click="chooseImage"></i>
      <input @change="photoChange($event)" type="file" id="upload_file" multiple style="display: none" />
    </div>
    <!-- 图片放大 -->
    <div v-if="showimg" class="fangda_cont" @click="closeimg">
      <div class="mengc"></div>
      <img :src="fangdaimg" class="fangdatu" v-if="showimg" ref="imgchicun" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'ChaKanZiXun',
  components: {},
  props: ['code'],
  data() {
    return {
      timer1: null,
      xinxishuju: [],
      fasongxinxi: '',
      fasongimng: '',
      fangdaimg: '',
      showimg: false,
      ch: 0
    }
  },
  watch: {
    xinxishuju(a, b) {
      // console.log(
      //   this.$refs.xinxikuang.scrollHeight - this.$refs.xinxikuang.scrollTop
      // )
      // console.log(this.$refs.xinxikuang.scrollHeight)
      console.log(this.ch)
      const len1 = a.length
      const len2 = b.length
      if (len2 === 0) {
        this.$nextTick(() => {
          this.$refs.xinxikuang.scrollTop = this.$refs.xinxikuang.scrollHeight
        })
        return
      }
      if (this.$refs.xinxikuang.scrollHeight - this.$refs.xinxikuang.scrollTop === this.ch) {
        this.$nextTick(() => {
          this.$refs.xinxikuang.scrollTop = this.$refs.xinxikuang.scrollHeight
        })
        return
      }
      if (len1 === len2) return
      if (a[len1 - 1].mem === '用户') return
      this.$nextTick(() => {
        this.$refs.xinxikuang.scrollTop = this.$refs.xinxikuang.scrollHeight
      })
    },
    fangdaimg() {
      this.$nextTick(() => {
        if (this.fangdaimg === '') {
          this.showimg = false
        } else {
          this.showimg = true
        }

        // this.$refs.imgchicun
      })
    }
  },
  computed: {},
  methods: {
    // 获取临时咨询信息
    async zixunxinxi() {
      const url = 'https://img.yx915.com/json/livechat/' + this.code + '/' + this.code + '.json'
      const { data: res } = await this.$axios({
        method: 'get',
        url: url,
        params: {
          v: Math.random()
        },
        dataType: 'text'
      })
      // console.log(res)
      this.xinxishuju = res
    },
    // 信息换行
    linefeed() {
      this.fasongxinxi += '\n'
    },
    // 客服发送信息给用户
    async zx_Write(sta) {
      if (sta === 'txt') {
        if (this.fasongxinxi === '') {
          alert('请输入信息')
          this.fasongxinxi = ''
          return
        }
      }

      const shijianc = new Date().getTime()
      const obj = {
        dd: this.code,
        txt: this.fasongxinxi,
        mem: localStorage.name
      }
      if (sta === 'img') {
        obj.txt = this.fasongimng
      }
      const { data: res } = await this.$axios({
        method: 'post',
        url: 'https://web.api.yx915.com/Chatsystem/zx_Write.aspx',
        // params:{},  // get请求参数
        data: JSON.stringify(obj), // post请求参数
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          'x-service-app': 'chenshi001',
          'x-service-token': this.$md5('chenshi001' + 'd832912d76d935cab49c76462312c848' + shijianc).toUpperCase(),
          'x-service-timestamp': shijianc
        }
      })
      // console.log(res)
      if (res[0].msg === '成功') {
        this.fasongxinxi = ''
        this.zx_Write8()
      }
    },
    // 清除未读数
    async zx_Write8() {
      const shijianc = new Date().getTime()
      const obj = {
        dd: this.dangqianjson.code,
        txt: 0
      }
      await this.$axios({
        method: 'post',
        url: 'https://Web.api.yx915.com/Chatsystem/zx_Write8.aspx',
        // params: {
        //   code: this.dangqianjson.code,
        //   Mem: localStorage.token
        // } // get请求参数
        data: JSON.stringify(obj), // post请求参数
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          'x-service-app': 'chenshi001',
          'x-service-token': this.$md5('chenshi001' + 'd832912d76d935cab49c76462312c848' + shijianc),
          'x-service-timestamp': shijianc
        }
      })
      // console.log(res)
    },
    // 上传图片 start
    chooseImage() {
      document.getElementById('upload_file').click() // 触发input-file文件上传控件 事件photoChange
    },
    photoChange(el) {
      const file = el.target.files[0] // name: "dangqi1.png" || type: "image/png"
      const type = file.type.split('/')[0]
      if (type === 'image') {
        // 将图片img转化为base64
        const reader = new FileReader()
        reader.readAsDataURL(file)
        const that = this
        reader.onloadend = function () {
          const dataURL = reader.result
          const blob = that.dataURItoBlob(dataURL)
          that.upload(blob) // 执行上传接口
        }
      } else {
        alert('上传了非图片')
      }
    },
    dataURItoBlob(dataURI) {
      // base64 解码
      const byteString = window.atob(dataURI.split(',')[1])
      const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]
      // const T = mimeString.split('/')[1]
      const ab = new ArrayBuffer(byteString.length)
      const ia = new Uint8Array(ab)
      for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i)
      }
      return new Blob([ab], { type: mimeString })
    },
    async upload(imgUrl) {
      const shijianc = new Date().getTime()
      const formdata = new FormData()
      formdata.append('file', imgUrl) // 下面是要传递的参数

      const { data: res } = await this.$axios.post('https://Web.api.yx915.com/Imgupdate/Url_Img_Zx.aspx', formdata, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'x-service-app': 'chenshi001',
          'x-service-token': this.$md5('chenshi001' + 'd832912d76d935cab49c76462312c848' + shijianc).toUpperCase(),
          'x-service-timestamp': shijianc
        }
      })
      // console.log(res)
      this.fasongimng = '<div class="getRecord kefu_linshi_cont"><img style="width: 166px;" src="' + res.data.src + '"></div>'
      this.zx_Write('img')
    }, // 上传图片 end
    // 放大图片
    showImg(e) {
      if (e.target.src) {
        this.fangdaimg = e.target.src
      }
    },
    closeimg() {
      this.showimg = false
      this.fangdaimg = ''
    }
  },
  created() {
    this.xinxishuju = []
    if (this.timer1 != null) {
      clearInterval(this.timer1)
    }
    this.timer1 = setInterval(() => {
      // 某些操作
      this.zixunxinxi()
    }, 1000)
  },
  mounted() {
    this.ch = this.$refs.xinxikuang.scrollHeight
  },
  filters: {},
  beforeDestroy() {
    clearInterval(this.timer1)
    this.timer1 = null
  },
  destroyed() {
    clearInterval(this.timer1)
    this.timer1 = null
  }
}
</script>
<style lang="less" scoped>
.cont {
  width: 1050px;
  text-align: left;
  background-color: #f5f5f5;
  display: inline-block;
  vertical-align: top;
  margin-top: 30px;
  .liaotian_cont {
    width: 1050px;
    height: calc(100vh - 284px);
    border: 1px solid #e5e5e5;
    padding: 0 20px;
    box-sizing: border-box;
    overflow: auto;
    padding-bottom: 10px;
    .xitong_tit {
      margin-top: 15px;
      span {
        display: inline-block;
        vertical-align: middle;
        font-size: 12px;
      }
      span:nth-child(1) {
        color: rgb(255, 91, 1);
      }
      span:nth-child(2) {
        margin-left: 8px;
        color: rgb(153, 153, 153);
      }
    }
    .xitong_fayan {
      margin-top: 10px;
      text-align: left;
      line-height: 1.6;
      padding: 6px;
      box-sizing: border-box;
      color: rgb(77, 77, 77);
      font-size: 12px;
      display: inline-block;
      background-color: #9eea65;
      border-radius: 3px;
      text-align: justify;
      max-width: 70%;
      ::v-deep .getRecord {
        img {
          width: 166px !important;
        }
      }
    }
    .kefu_tit {
      margin-top: 15px;
      span {
        display: inline-block;
        vertical-align: middle;
        font-size: 12px;
      }
      span:nth-child(1) {
        color: rgb(0, 0, 0);
      }
      span:nth-child(2) {
        margin-left: 8px;
        color: rgb(153, 153, 153);
      }
    }
    .kefu_fayan {
      margin-top: 10px;
      text-align: left;
      border-radius: 3px;
      background-color: #e6e6e6;
      color: rgb(77, 77, 77);
      font-size: 12px;
      line-height: 1.6;
      padding: 6px;
      box-sizing: border-box;
      display: inline-block;
      text-align: justify;
      max-width: 70%;
      white-space: pre-wrap;
      ::v-deep .getRecord {
        img {
          width: 166px !important;
        }
      }
    }
    .my_tit {
      margin-top: 15px;
      text-align: right;
      p {
        font-size: 12px;
        color: rgb(153, 153, 153);
      }
    }
    .my_fayan {
      text-align: right;
      p {
        margin-top: 10px;
        line-height: 1.6;
        padding: 6px;
        box-sizing: border-box;
        color: rgb(77, 77, 77);
        font-size: 12px;
        display: inline-block;
        background-color: #fff;
        border-radius: 3px;
        text-align: justify;
        max-width: 70%;
        word-wrap: break-word;
        white-space: pre-wrap;
        ::v-deep .getRecord {
          img {
            width: 166px !important;
          }
        }
      }
    }
    ::v-deep .getRecord {
      margin-top: 5px;
      img {
        max-width: 166px;
      }
    }
  }
  .gongnenganniu {
    height: 44px;
    line-height: 44px;
    padding: 0 20px;
    border-left: 1px solid #e5e5e5;
    border-right: 1px solid #e5e5e5;
    width: 100%;
    box-sizing: border-box;
    .lianxi_btn {
      float: right;
      position: relative;
      height: 44px;
      line-height: 44px;
      text-align: center;
      width: 110px;
      cursor: pointer;
      display: inline-block;
      vertical-align: middle;
      .iconfont {
        color: #ff9f00;
        display: inline-block;
        vertical-align: middle;
      }
      p {
        font-size: 14px;
        color: #000000;
        margin-left: 5px;
        display: inline-block;
        vertical-align: middle;
      }
      span {
        display: block;
        border-radius: 50%;
        background-color: #ff0000;
        font-size: 12px;
        position: absolute;
        top: 2px;
        right: 2px;
        color: #fff;
        width: 20px;
        height: 20px;
        line-height: 20px;
        text-align: center;
        display: none;
      }
    }
    .lianxi_btn.act {
      background-color: #fff;
    }
  }
  .shurulan {
    width: 100%;
    height: 135px;
    background-color: #fff;
    border-left: 1px solid #e5e5e5;
    border-right: 1px solid #e5e5e5;
    padding: 20px;
    box-sizing: border-box;
    textarea {
      width: 100%;
      border: 0;
      font-size: 14px;
      color: #000000;
      height: 60px;
      resize: none;
      outline: none;
    }
    div {
      text-align: right;
      margin-top: 5px;
      p {
        font-size: 12px;
        color: #999999;
        height: 30px;
        line-height: 30px;
        display: inline-block;
        vertical-align: middle;
      }
      a {
        display: inline-block;
        margin-left: 8px;
        height: 30px;
        line-height: 30px;
        width: 64px;
        color: #fff;
        font-size: 14px;
        background-color: #0081fb;
        text-align: center;
        border-radius: 5px;
        text-decoration: none;
      }
    }
  }
}
.fangda_cont {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: space-around;
  align-items: center;
  .mengc {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
  }
  img {
    position: absolute;
    max-height: 80%;
  }
}
</style>
