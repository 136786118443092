import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/login/login.vue'
import ZiXun from '../views/zixun/zixun.vue'
import Index from '../views/index/index.vue'
import Order from '../views/order/order.vue'
import ChaKanC from '../components/chakan/chakanc.vue'
import ChaKanZiXun from '../components/chakan/chakanzixun.vue'

Vue.use(VueRouter)

const routes = [
  { path: '/', redirect: '/index' },
  {
    path: '/index',
    name: 'Index',
    component: Index,
    redirect: '/index/zixun',
    children: [
      {
        path: 'zixun',
        name: 'ZiXun',
        component: ZiXun,
        meta: { title: '网站咨询' }
      },
      {
        path: 'order',
        name: 'Order',
        component: Order,
        meta: { title: '订单咨询' }
      },
      {
        path: 'chakanc/:code',
        name: 'ChaKanC',
        component: ChaKanC,
        meta: { title: '买家订单信息查看' },
        props: true
      }
    ]
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: { title: '客服登录' }
  },
  {
    path: '/chakanzixun/:code',
    name: 'ChaKanZiXun',
    component: ChaKanZiXun,
    meta: { title: '咨询订单查看' },
    props: true
  },
  {
    path: '/yxbichaxun/:code',
    name: 'Yxbichaxun',
    component: () => import('@/components/chakan/yxbi'),
    meta: { title: '咨询订单查看' },
    props: true
  }
]

const router = new VueRouter({
  routes
})

router.beforeEach((to, from, next) => {
  const patharr = ['/index/zixun', '/index/order']
  if (patharr.indexOf(to.path) !== -1) {
    const token = localStorage.getItem('token')
    if (token) {
      next()
    } else {
      next('/login')
    }
  } else {
    next()
  }
})

export default router
