<template>
  <div style="display: inline-block; vertical-align: top">
    <!-- 订单列表 -->
    <div class="dingdan_list">
      <div class="list_cont">
        <template v-for="item in zixunshuju">
          <a href="javascript:;" :class="item.code === dangqianjson.code ? 'dingdan_item act' : 'dingdan_item'" :key="item.ID" @click="zixunrenling(item)" v-if="item.Kefu === ''">
            <img src="@/img/男生-粉.png" />
            <div>
              <p>
                <span class="lan">咨询</span><span>{{ item.code }}</span>
              </p>
              <p>游戏: {{ item.Yx }}</p>
              <p>咨询时间: {{ item.date }}</p>
            </div>
            <p class="weidunum" v-if="item.num > 0">
              {{ item.num }}
            </p>
            <p class="ddstatas">待处理</p>
          </a>
          <a href="javascript:;" :class="item.code === dangqianjson.code ? 'dingdan_item act' : 'dingdan_item'" :key="item.ID" @click="zixunqiehuan(item)" v-else>
            <img src="@/img/男生-粉.png" />
            <div>
              <p>
                <span class="lan">咨询</span><span>{{ item.code }}</span>
              </p>
              <p>游戏: {{ item.Yx }}</p>
              <p>咨询时间: {{ item.date }}</p>
            </div>
            <p class="weidunum" v-if="item.num > 0">
              {{ item.num }}
            </p>
            <p class="ddstatas" style="background-color: #0081fb">处理中</p>
          </a>
        </template>
      </div>
    </div>
    <!-- 聊天信息及订单状态、详情、进度 -->
    <div class="zhuti">
      <!-- 头部账号基本详情 -->
      <div class="jiaoliuzhanghao">
        <img class="dingdan_img" src="@/img/男生-粉.png" />
        <div>
          <p>
            <span style="width: 50px" class="lan">咨询</span><span class="tit_nr">{{ dangqianjson.code }}</span>
          </p>
          <p class="qufu_nr">IP:{{ dangqianjson.ip }}</p>
        </div>
        <div v-if="dangqianjson.code">
          <button type="button" @click="guaqihuihua(dangqianjson)" class="gauqibtn">挂 起</button>
        </div>
      </div>
      <XinXi :code="dangqianjson.code"></XinXi>
      <!-- 左侧咨询状态详情 -->
      <div class="zixun_cont">
        <!-- 按钮操作 -->
        <div class="btn_cont">
          <button type="button" @click="tanchuceng = 1">客服转接</button>
          <button type="button" @click="dasongpingjia">发送评价</button>
          <button type="button" @click="firm">结束会话</button>
        </div>
        <!-- 咨询情况 -->
        <div class="jiaoyiqingk">
          <div>
            <img src="@/img/huihua_s.png" />
            <div>
              <p class="jiayista">当前状态：咨询中</p>
              <p>当前客服：{{ dangqianjson.Kefu == '' ? '暂无客服' : dangqianjson.Kefu }}</p>
            </div>
          </div>
          <p>
            <span>用户IP：</span><span>{{ dangqianjson.ip }}</span>
          </p>
          <p>
            <span>用户是否登录：</span><span>{{ dangqianjson.Mem == '' ? '否' : '是' }}</span>
          </p>
          <p v-if="dangqianjson.Mem != ''">
            <span>用户id：</span><span>{{ dangqianjson.Mem }}</span>
          </p>
        </div>
        <!-- 网站咨询者信息 -->
        <div class="jiaoyiqingk flex_cont">
          <p>
            <span>咨询编号：</span><span>{{ dangqianjson.code }}</span>
          </p>
          <p>
            <span>创建时间：</span><span>{{ dangqianjson.date }}</span>
          </p>
        </div>
        <!-- 订单详情 -->
        <div class="zhanghaoqiangk">
          <div>
            <p><span>订单编号：</span><span class="dingdanbiaohao"></span></p>
            <p><span>创建时间：</span><span class="dingdandata"></span></p>
          </div>
          <div>
            <img class="dingdan_img" src="@/img/daojutongyong.jpg" />
            <div>
              <p class="tit_nr"></p>
              <p>账号属性：</p>
              <p class="qufu_nr">游戏/区/服：</p>
            </div>
          </div>
          <div>
            <p><span>订单金额：</span><span class="dingdan_price">￥0.00</span></p>
            <p><span>包赔费用：</span><span>￥0.00</span></p>
            <p>
              <span>实际支付：</span>
              <span style="color: #ff9f00" class="dingdan_price">￥0.00</span>
            </p>
          </div>
        </div>
        <div class="dingdanjindu">
          <!-- <p>
            <span>2021-12-26 14:25:47</span
            ><span>客服小姐姐赈灾操作中，请稍等.....</span>
          </p>
          <p>
            <span>2021-12-26 14:25:47</span
            ><span>客服小姐姐赈灾操作中，请稍等.....</span>
          </p> -->
        </div>
      </div>
    </div>
    <KeFuList :codes="dangqianjson.code" :isnum="tanchuceng" @guanbi="xiugaitanchu"></KeFuList>
  </div>
</template>

<script>
import XinXi from '@/components/xinxi/XinXi'
import KeFuList from '@/components/kefulist/kefulist.vue'
import $ from 'jquery'
export default {
  name: 'ZiXun',
  components: { XinXi, KeFuList },
  props: [],
  data() {
    return {
      zixunshuju: [],
      dangqianjson: {},
      zixuntimer: null,
      tanchuceng: 0,
      snum: 0,
      huihualen: 99,
      fristcode: '',
      dangqianxinxishu: 0
    }
  },
  watch: {
    snum() {
      this.$emit('zixunshu', this.snum)
    },
    zixunshuju: {
      handler: function () {
        const nums = this.zixunshuju.reduce((amt, item) => {
          return (amt += item.num)
        }, 0)
        // console.log(nums)

        if (nums > this.dangqianxinxishu && nums > 0) {
          if (window.Notification && Notification.permission !== 'denied') {
            Notification.requestPermission(function (status) {
              const m = new Notification('叮~~', {
                body: 'YX915提醒您有新的网站咨询消息，请及时处理'
              })
              m.onclose = function () {
                m.close()
              }
            })
          }
        }
        this.dangqianxinxishu = nums
      },
      deep: true
    }
  },
  computed: {},
  methods: {
    // 获取咨询列表
    async zixunlist() {
      try {
        const { data: res } = await this.$axios.get('https://web.api.yx915.com/Chatsystem/N_ZIXUNLIST.aspx', {
          params: {
            Yx: '',
            Kefu: localStorage.token
          }
        })

        let arr = res[0].Dingdan.map((item) => {
          return {
            ...item,
            num: this.weidu(item.code)
          }
        })
        arr = arr.filter((item) => {
          return item.Yx !== 'wjwz'
        })

        const boolarr = arr.every((item) => item.Kefu !== '')

        if (!boolarr || arr.length > this.huihualen) {
          // 系统提示音
          if (window.Notification && Notification.permission !== 'denied') {
            Notification.requestPermission().then(function (status) {
              const m = new Notification('叮~~', {
                body: 'YX915提醒您有新的网站咨询消息，请及时处理'
              })
              m.onclose = function () {
                m.close()
              }
            })
          }
        }

        this.zixunshuju = arr.filter((item) => {
          return item.Yx !== 'wjwz'
        })
        this.huihualen = arr.length

        if (arr.length > 0) {
          this.fristcode = arr[0].code
        }
      } catch (error) {
        console.log(error)
      }
    },
    // 切换咨询用户
    zixunqiehuan(e) {
      console.log(e)
      this.dangqianjson = e
      this.sendZXWrite8(e.code)
    },
    // 客服认领咨询
    async zixunrenling(e) {
      try {
        const { data: res } = await this.$axios.get('https://web.api.yx915.com/Chatsystem/N_RENLING.aspx', {
          params: {
            Lx: 'zx',
            code: e.code,
            Mem: localStorage.token,
            name: localStorage.name
          }
        })
        if (res[0].msg === '成功') {
          alert(res[0].number)
          this.dangqianjson = e
          this.sendZXWrite8(e.code)
        } else {
          alert(res[0].number)
        }
      } catch (error) {
        console.error(error)
      }
    },
    // 挂起会话
    async guaqihuihua(e) {
      const { data: res } = await this.$axios({
        method: 'get',
        url: 'https://web.api.yx915.com/Chatsystem/N_GUAQI.aspx',
        params: {
          Lx: 'zx',
          code: e.code,
          Mem: localStorage.token,
          name: localStorage.name
        } // get请求参数
        // data: JSON.stringify(obj), // post请求参数
      })
      // console.log(res)
      if (res[0].msg === '成功') {
        alert(res[0].number)
        this.dangqianjson = e
      } else {
        alert(res[0].number)
      }
    },
    firm() {
      // 利用对话框返回的值 （true 或者 false）
      if (confirm('你确定结束会话嘛？')) {
        this.zixunjieshu()
      }
    },
    // 结束咨询会话
    async zixunjieshu() {
      try {
        const { data: res } = await this.$axios.get(`https://web.api.yx915.com/Chatsystem/N_ZIXUNJIESHU.aspx?code=${this.dangqianjson.code}&Mem=${localStorage.token}`)
        if (res[0].msg === '成功') {
          alert(res[0].number)
          this.dangqianjson = {}
          await this.zixunlist()
        } else {
          alert(res[0].number)
        }
      } catch (error) {
        console.error(error)
        alert('网络错误，请稍后再试')
      }
    },
    // 发送会话
    async dasongpingjia() {
      const nr = `
        <p class="kefupingjia">已发送评价</p>
        <div class="pingjia">
          <p class="pingjia_tit">感谢您的信任，给个评价吧</p>
          <a href="javascript:haoping('好评');" class="pingjia_item pingjia_item1" style="margin-left: 0;">好评</a>
          <a href="javascript:haoping('中评');" class="pingjia_item pingjia_item2">中评</a>
          <a href="javascript:haoping('差评');" class="pingjia_item pingjia_item3">差评</a>
          <textarea placeholder="我还有话说"></textarea>
          <a href="javascript:tijiaopingjia('${localStorage.token}');" class="pingjia_btn">提交评价</a>
        </div>
      `
      this.fasongpingjia(nr)
    },
    // 客服发送信息给用户
    async fasongpingjia(sta) {
      try {
        const shijianc = new Date().getTime()
        const obj = {
          dd: this.dangqianjson.code,
          txt: sta,
          mem: localStorage.name
        }
        await this.$axios({
          method: 'post',
          url: 'https://web.api.yx915.com/Chatsystem/zx_Write.aspx',
          // params:{},  // get请求参数
          data: JSON.stringify(obj), // post请求参数
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'x-service-app': 'chenshi001',
            'x-service-token': this.$md5('chenshi001' + 'd832912d76d935cab49c76462312c848' + shijianc).toUpperCase(),
            'x-service-timestamp': shijianc
          }
        })
      } catch (error) {
        console.error(error)
      }
    },
    xiugaitanchu() {
      this.tanchuceng = 0
      // 客服转接成功当前信息情况
      this.dangqianjson = []
      this.zixunlist()
    },
    // 获取未读信息总数
    weidu(e) {
      const url = 'https://img.yx915.com/json/livechat/' + e + '/' + e + 'y.json?v=' + Math.random()
      let zongshu = 0
      $.ajaxSettings.async = false
      $.getJSON(url, function (res) {
        // console.log(res.replace(/[^\d]/g, ''))
        zongshu = res
      })
      return zongshu
    },
    // 清除未读数
    async sendZXWrite8(dd) {
      const timestamp = new Date().getTime()
      const requestBody = {
        dd: dd,
        txt: 0
      }
      await this.$axios({
        method: 'post',
        url: 'https://Web.api.yx915.com/Chatsystem/zx_Write8.aspx',
        data: JSON.stringify(requestBody),
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          'x-service-app': 'chenshi001',
          'x-service-token': this.$md5('chenshi001' + 'd832912d76d935cab49c76462312c848' + timestamp),
          'x-service-timestamp': timestamp
        }
      })
    }
  },
  created() {
    this.zixunlist()
    this.zixuntimer = setInterval(() => {
      // 某些操作
      this.zixunlist()
    }, 8000)
  },
  mounted() {},
  filters: {},
  beforeDestroy() {
    clearInterval(this.zixuntimer)
    this.zixuntimer = null
  },
  destroyed() {
    clearInterval(this.zixuntimer)
    this.zixuntimer = null
  }
}
</script>
<style lang="less" scoped>
.flex_cont {
  display: flex;
  justify-content: space-between;
}
.lan {
  background-color: green !important;
}
// 左侧选项
.qiehuai {
  width: 64px;
  height: calc(100vh - 50px);
  background-color: #0081fb;
  .user_img {
    width: 32px;
    height: 32px;
    border-radius: 3px;
    margin-top: 16px;
    margin-bottom: 16px;
  }
  .qiehuai_item {
    width: 100%;
    text-align: center;
    padding: 5px 0;
    display: inline-block;
    text-decoration: none;
    .iconfont {
      font-size: 26px;
      color: #7ac1ff;
    }
    p {
      font-size: 12px;
      color: #7ac1ff;
      margin-top: 5px;
    }
  }
  .qiehuai_item.act {
    background-color: #ff9f00;
    .iconfont {
      color: #fff;
    }
    p {
      color: #fff;
    }
  }
  .qiehuai_item.tishi {
    .iconfont {
      color: #fff;
    }
    p {
      color: #fff;
    }
  }
}
// 订单列表
.dingdan_list {
  width: 300px;
  background-color: #fff;
  position: relative;
  display: inline-block;
  vertical-align: top;
  .list_cont {
    height: calc(100vh - 50px);
    position: relative;
    overflow: auto;
    padding: 0 16px;
    box-sizing: border-box;
    .dingdan_item {
      margin-top: 16px;
      display: block;
      position: relative;
      text-align: left;
      img {
        width: 60px;
        height: 60px;
        display: inline-block;
        vertical-align: middle;
      }
      div {
        margin-left: 8px;
        width: 180px;
        display: inline-block;
        vertical-align: middle;
        p:nth-child(1) {
          width: 180px;
          span {
            display: inline-block;
            vertical-align: middle;
            height: 16px;
            line-height: 16px;
            font-size: 12px;
            color: #000000;
            margin-left: 5px;
          }
          span:nth-child(1) {
            width: 36px;
            text-align: center;
            background-color: #ff0000;
            color: #fff;
            margin-left: 0;
            border-radius: 3px;
          }
          span:nth-child(2) {
            overflow: hidden;
            text-overflow: ellipsis;
            width: 136px;
          }
        }
        p:nth-child(2) {
          font-size: 14px;
          color: #ff9c00;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          margin-top: 3px;
        }
        p:nth-child(3) {
          font-size: 12px;
          color: #999;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          margin-top: 3px;
        }
      }
      .weidunum {
        border-radius: 50%;
        background-color: #ff0000;
        font-size: 0.375rem;
        position: absolute;
        top: -0.15625rem;
        left: 1.4375rem;
        color: #fff;
        width: 0.625rem;
        height: 0.625rem;
        line-height: 0.625rem;
        text-align: center;
      }
      .ddstatas {
        width: 60px;
        height: 20px;
        line-height: 20px;
        text-align: center;
        font-size: 10px;
        background-color: rgba(0, 0, 0, 0.5);
        color: #fff;
        position: absolute;
        left: 0;
        bottom: 0;
      }
    }
    .dingdan_item.act {
      background-color: rgba(0, 0, 0, 0.1);
    }
  }
  .list_cont::after {
    content: '';
    height: 1px;
    width: 300px;
    background-color: #e5e5e5;
    position: absolute;
    left: 0;
    top: 0;
  }
  .list_cont::-webkit-scrollbar {
    /*滚动条整体样式*/
    width: 3px; /*高宽分别对应横竖滚动条的尺寸*/
    height: 1px;
  }
  .list_cont::-webkit-scrollbar-thumb {
    /*滚动条里面小方块*/
    border-radius: 60px;
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    background: #999;
  }
  .list_cont::-webkit-scrollbar-track {
    /*滚动条里面轨道*/
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    background: #ededed;
  }
}
// 聊天信息及订单状态、详情、进度
.zhuti {
  display: inline-block;
  vertical-align: top;
  // 头部账号基本详情
  .jiaoliuzhanghao {
    width: calc(100vw - 364px);
    height: 65px;
    padding: 12px 20px;
    box-sizing: border-box;
    background-color: #fff;
    border-left: 1px solid #e5e5e5;
    border-top: 1px solid #e5e5e5;
    text-align: left;
    img {
      width: 40px;
      height: 40px;
      display: inline-block;
      vertical-align: middle;
    }
    div {
      margin-left: 10px;
      display: inline-block;
      vertical-align: middle;
      p {
        width: 1100px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
      p:nth-child(1) {
        span {
          font-size: 14px;
          color: #000000;
          height: 16px;
          line-height: 16px;
          margin-left: 5px;
          display: inline-block;
          vertical-align: middle;
        }
        span:nth-child(1) {
          margin-left: 0;
          background-color: #ff0000;
          color: #fff;
          width: 36px;
          text-align: center;
          border-radius: 3px;
        }
      }
      p:nth-child(2) {
        font-size: 12px;
        color: #999999;
        margin-top: 5px;
      }
    }
  }
  // 左侧咨询状态详情
  .zixun_cont {
    width: 506px;
    display: inline-block;
    vertical-align: top;
    text-align: left;
    background-color: #f5f5f5;
    .btn_cont {
      display: flex;
      justify-content: space-between;
      padding: 15px;
      background-color: #fff;
      width: 100%;
      box-sizing: border-box;
      border-top: 1px solid #e5e5e5;
      border-bottom: 1px solid #e5e5e5;
      button {
        width: 150px;
        height: 48px;
        line-height: 48px;
        font-size: 16px;
        color: #fff;
        border-radius: 5px;
        border: 0;
        cursor: pointer;
      }
      button:nth-child(1) {
        background-color: #ff9f00;
      }
      button:nth-child(2) {
        background-color: #f00;
      }
      button:nth-child(3) {
        background-color: #14446a;
      }
    }
    .jiaoyiqingk {
      margin-top: 15px;
      width: 100%;
      background-color: #fff;
      padding: 15px;
      border-top: 1px solid #e5e5e5;
      border-bottom: 1px solid #e5e5e5;
      box-sizing: border-box;
      div:nth-child(1) {
        padding: 9px;
        border: 1px solid #e5e5e5;
        img {
          width: 50px;
          height: 50px;
          display: inline-block;
          vertical-align: middle;
        }
        div {
          margin-left: 10px;
          display: inline-block;
          vertical-align: middle;
          p:nth-child(1) {
            font-size: 18px;
            color: #000000;
          }
          p:nth-child(2) {
            font-size: 12px;
            color: #999999;
            margin-top: 3px;
          }
        }
      }
      p.jiayista {
        margin-top: 0;
      }
      p {
        margin-top: 10px;
        span {
          font-size: 12px;
        }
        span:nth-child(1) {
          color: #999999;
        }
        span:nth-child(2) {
          color: #000;
        }
        span:nth-child(3) {
          color: #999999;
        }
      }
    }
    .zhanghaoqiangk {
      width: 100%;
      background-color: #fff;
      margin-top: 20px;
      padding: 15px;
      box-sizing: border-box;
      text-align: left;
      div:nth-child(1) {
        p {
          display: inline-block;
          vertical-align: middle;
          span {
            font-size: 12px;
          }
          span:nth-child(1) {
            color: #999999;
          }
          span:nth-child(2) {
            color: #000;
          }
        }
        p:nth-child(1) {
          width: 300px;
        }
      }
      div:nth-child(2) {
        width: 100%;
        padding: 10px;
        box-sizing: border-box;
        border: 1px solid #e5e5e5;
        margin-top: 10px;
        img {
          width: 80px;
          height: 80px;
          display: inline-block;
          vertical-align: middle;
        }
        div {
          margin: 0;
          margin-left: 10px;
          width: 350px;
          display: inline-block;
          vertical-align: middle;
          border: 0;
          p {
            width: 100%;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
          }
          p:nth-child(1) {
            font-size: 14px;
            color: #000000;
          }
          p:nth-child(2) {
            color: #999999;
            font-size: 12px;
            // margin-top: 10px;
          }
          p:nth-child(3) {
            color: #999999;
            font-size: 12px;
            margin-top: 6px;
          }
        }
      }
      div:nth-child(3) {
        margin-top: 10px;
        p {
          display: inline-block;
          vertical-align: middle;
          width: calc(100% / 3);
          span {
            font-size: 12px;
          }
          span:nth-child(1) {
            color: #999999;
          }
          span:nth-child(2) {
            color: #000;
          }
        }
      }
    }
    .dingdanjindu {
      width: 100%;
      padding: 15px;
      box-sizing: border-box;
      margin-top: 20px;
      background-color: #fff;
      height: calc(100vh - 780px);
      overflow: auto;
      p {
        margin-top: 15px;
        font-size: 12px;
        span {
          font-size: 12px;
        }
        span:nth-child(1) {
          color: #999999;
        }
        span:nth-child(2) {
          color: #000;
          margin-left: 5px;
        }
      }
      p:nth-child(1) {
        margin-top: 0;
        span:nth-child(1) {
          color: #ff9f00;
        }
        span:nth-child(2) {
          color: #ff9f00;
        }
      }
    }
  }
}
.gauqibtn {
  height: 40px;
  line-height: 40px;
  width: 120px;
  text-align: center;
  font-size: 16px;
}
</style>
