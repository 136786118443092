import Vue from 'vue'
import App from './App.vue'
import router from './router'
import axios from 'axios'
import md5 from 'js-md5'

import './assets/iconfont/iconfont.css'

Vue.config.productionTip = false

Vue.prototype.$axios = axios
Vue.prototype.$md5 = md5

router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title
  }
  next()
})

new Vue({
  router,
  render: (h) => h(App)
}).$mount('#app')
